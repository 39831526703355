import React from "react"
import Layout from "../components/layout"

const Feedback = () => {
  return (
    <Layout>
      <div className="h-screen gradient py-24">
        <form
          className="w-3/4 md:w-1/2 max-w-xs mx-auto shadow-custom p-8 rounded bg-white"
          method="POST"
          action="/"
          name="feedback"
          data-netlify="true"
        >
          <input
            type="hidden"
            className="hidden"
            name="form-name"
            value="feedback"
          />
          <h2>Feedback</h2>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="email"
            >
              E-mail
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
              type="text"
              placeholder="email@mail.com"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Feedback is welcome!
            </label>

            <textarea
              id="text"
              name="reason"
              type="text"
              placeholder="Anything you can think of"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <input
              className="items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
              type="submit"
              value="Send"
            />
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Feedback
